<template>
	<div class="container">
		<img class="w-100" :src="require('../../assets/img/icon/banner.png')" alt="">

		<div class="row paper m-t-2">
			<h2 class="col-12 p-4">
				W3 DAO Bond
			</h2>
			<div class="col-sm-6 col-12" style="line-height:50px;">
				<div class="c-999 text-center">{{store.state.lang.remainingQuatity}}</div>
				<div class="text-center pool-sub">{{maxMintCrsNumFormate}} | {{maxQuantityFm}}</div>
			</div>
			<!-- <div class="col-sm-6 col-12 row w3c-price">
				<div class="c-000 text-center col-sm-12 col-6 pool-t ls-flex-ajc">
					<div class="ls-flex-ajc">$W3C {{store.state.lang.price}}</div>
				</div>
				<div class="text-center col-sm-12 col-6 pool-sub">$ {{crsFromatePrice}}</div>
			</div> -->

			<div class="col-sm-6 col-12" style="line-height:40px;">
				<div class="c-999 text-center">W3C{{store.state.lang.price}}</div>
				<div class="text-center pool-sub">$ {{crsFromatePrice}}</div>
			</div>
		</div>

		<div class="row paper m-t-2">
			<h6 class="row col-12 m-t-3 p-2 h5-none">
				<div class="col-sm-4">{{store.state.lang.token}}</div>
				<div class="col-sm-2">{{store.state.lang.price}}</div>
				<div class="col-sm-2">{{store.state.lang.amountW3B}}</div>
				<div class="col-sm-2">{{store.state.lang.totalMinted}}</div>
				<div class="col-sm-2"></div>
			</h6>

			<div class="row col-sm-12 m-t-2 p-2" v-for="(item,index) in CoinInfoList" :key="index">
				<div class="col-sm-4 col-12 ls-flex-ajb">
					<img class="icon-coin mr-2" :src="item.icon" alt="">
					<div class="ls-flex-1">
						<div class="ls-flex-ajb">
							{{item.name}}
							<div class="buy-btn ml-3 ls-flex-ajc">
								<a :href="item.url" target="_blank">{{store.state.lang.buy}}</a>
							</div>
						</div>
						<div class="add-w" @click="addCoinToWallet(index)">
							{{store.state.lang.addToWallet}}
							<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
								class="bi bi-arrow-up-right-circle" viewBox="0 0 16 16">
								<path fill-rule="evenodd"
									d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z" />
							</svg>
						</div>
					</div>
				</div>
				<div class="col-sm-2 col-6 mt-3 mb-3">$ {{item.fromatePrice}}</div>
				<div class="col-sm-2 col-6 mt-3 mb-3">{{item.oneMintCrsNumFm}} W3B</div>
				<div class="col-sm-2 col-12 mt-3 mb-3">${{item.totalMintFm}}</div>
				<div class="col-sm-2 col-12 aprrove-btn" v-if="item.approveState" @click="openMintBox(item.name,index)">
					{{store.state.lang.mint}}</div>
				<div class="col-sm-2 col-12 aprrove-btn" v-else @click="openApproveBox(index)">
					{{store.state.lang.approve}} {{item.name}}</div>
			</div>
		</div>

		<!-- Mint 弹窗 -->
		<el-dialog v-model="dialogFormVisible" :center="true" :close-on-click-modal="false" custom-class="msgClass"
			@close="closeDialog()">
			<template #header>
				<h3 class="mint-header text-center ls-flex-ajc">
					<img class="mr-2" :src="require('../../assets/img/icon/logo_w.png')" alt="">
					W3
				</h3>
			</template>
			<div class="text-center c-999" style="font-size:18px;">{{store.state.lang.instantPayout}}</div>

			<div class="ls-flex-ajc mint-bar mb-3">
				<div class="ls-flex-1 ls-flex-ajc" @click="changeBarIndex(0)" :class="{'active':mintBarIndex==0}">
					{{store.state.lang.bondText}}</div>
				<div class="ls-flex-1 ls-flex-ajc" @click="changeBarIndex(1)" :class="{'active':mintBarIndex==1}">
					{{store.state.lang.redeem}}</div>
			</div>

			<div class="row" v-if="mintBarIndex==0">
				<el-input class="col-12 col-sm-12 col-md-9" v-model="mintAmount" type="number"
					placeholder="Input Amount" @input="getMintAmount" />
				<div class="max-coin ls-flex-ajc col-12 col-sm-12 col-md-3" @click="getMaxNum()">
					MAX <span></span> {{CoinInfoList[mintIndex].name}}
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
						class="bi bi-chevron-down" viewBox="0 0 16 16">
						<path fill-rule="evenodd"
							d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
					</svg>
				</div>
			</div>
			<div class="ls-flex-ajc">
				<div v-if="mintBarIndex==0 && CoinInfoList[mintIndex].approveState" class="mint-btn ls-flex-ajc" @click="mint()" :class="{'ls-bg':mintAmount}">
					{{store.state.lang.mint}}</div>
					
				<div v-if="!CoinInfoList[mintIndex].approveState && mintBarIndex!=1" class="mint-btn ls-flex-ajc ls-bg"
					@click="openApproveBox(dialogIndex)">{{store.state.lang.approve}}
					{{CoinInfoList[mintIndex].name}}</div>
				
				<div v-if="mintBarIndex==1" class="mint-btn ls-flex-ajc ls-bg" @click="claim()">Claim</div>
			</div>


			<div v-if="mintBarIndex==0">
				<div class="ls-flex-ajb balance-item">
					<div>{{store.state.lang.yourBalance}}</div>
					<div>{{CoinInfoList[mintIndex].balanceFm}} {{CoinInfoList[mintIndex].name}}</div>
				</div>
				<div class="ls-flex-ajb balance-item">
					<div>{{store.state.lang.WillGet}}</div>
					<div>{{canmintCrs}} W3B</div>
				</div>
				<div class="ls-flex-ajb balance-item">
					<div>{{store.state.lang.maxMint}}</div>
					<div>{{maxMintCrsNumFormate}} W3B</div>
				</div>
				<div class="ls-flex-ajb balance-item">
					<div>{{store.state.lang.vestingTerm}}</div>
					<div>7days</div>
				</div>
			</div>
			<div v-else>
				<div class="ls-flex-ajb balance-item">
					<div>{{store.state.lang.PendingRewards}}</div>
					<div>{{PendingRewards}} W3B</div>
				</div>
				<div class="ls-flex-ajb balance-item">
					<div>{{store.state.lang.ClaimableReward}} W3B</div>
					<div>{{claimVal}} W3B</div>
				</div>
				<div class="ls-flex-ajb balance-item">
					<div>{{store.state.lang.TimefullyVested}}</div>
					<div>{{Timefully}} days</div>
				</div>
				<div class="ls-flex-ajb balance-item">
					<div>{{store.state.lang.vestingTerm}}</div>
					<div>7days</div>
				</div>
			</div>
		</el-dialog>
		
		<comModal :showState="showModalState" :State="stateNum" :StateInfo="StateInfo" :tHash="tHash"></comModal>
		
	</div>
</template>

<script setup>
	import {ref,watch,onMounted} from 'vue';
	import {useStore} from 'vuex';
	import {mintAddr,approveUsdtAddr,approveBUSDAddr,approveUSDCAddr} from '@/utils';
	import {approveAbi} from '@/utils/approveAbi.json';
	import {mintAbi} from '@/utils/mintAbi.json';
	import Web3 from "web3";
	import formate from "@/utils/formate.js";
	import {ElMessageBox,ElMessage} from 'element-plus';
	import verify from '@/utils/verify.js';
	import axios from 'axios';
	import comModal  from "@/components/com-modal/com-modal.vue";
	import { thousandth } from '@clownlee/utils';
	
	const showModalState = ref(false);
	const StateInfo = ref('');
	const stateNum = ref(0);
	const tHash = ref('');
	
	
	const store = useStore();
	const maxQuantity = ref(1000000000);
	const maxQuantityFm = ref(1000000000);
	
	maxQuantityFm.value = thousandth(maxQuantityFm.value);

	const crsPrice = ref(0);
	const crsFromatePrice = ref(0);
	const maxMintCrsNum = ref(0);
	const maxMintCrsNumFormate = ref(0);
	const mintAmount = ref('');
	const dialogFormVisible = ref(false);
	const mintBarIndex = ref(0);
	const mintIndex = ref('');
	const canmintCrs = ref(0);
	const claimVal = ref(0);
	const PendingRewards = ref(0);
	const Timefully = ref(0);
	const dialogIndex = ref(0);

	const CoinInfoList = ref([{
			name: 'USDT',
			address: '0x55d398326f99059fF775485246999027B3197955',
			image: 'https://pancakeswap.finance/images/tokens/0x55d398326f99059fF775485246999027B3197955.png',
			balance: 0,
			balanceFm: 0,
			canMintCrs: 0,
			approveAmount: 0,
			approveState: false,
			totalMint:0,
			totalMintFm:0,
			price: 1.00,
			fromatePrice: 1.00,
			oneMintCrsNum: 0,
			oneMintCrsNumFm: 0,
			cIndex:4,
			icon: require('../../assets/img/icon_USDT.png'),
			url: 'https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955'
		},
		{
			name: 'BUSD',
			address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
			image: 'https://pancakeswap.finance/images/tokens/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png',
			balance: 0,
			balanceFm: 0,
			canMintCrs: 0,
			approveAmount: 0,
			approveState: false,
			totalMint:0,
			totalMintFm:0,
			price: 1.00,
			fromatePrice: 1.00,
			oneMintCrsNum: 0,
			oneMintCrsNumFm: 0,
			cIndex:5,
			icon: require('../../assets/img/icon_BUSD.png'),
			url: 'https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
		},
		{
			name: 'USDC',
			address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
			image: 'https://pancakeswap.finance/images/tokens/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d.png',
			balance: 0,
			canMintCrs: 0,
			balanceFm: 0,
			approveAmount: 0,
			approveState: false,
			totalMint:0,
			totalMintFm:0,
			price: 1.00,
			fromatePrice: 1.00,
			oneMintCrsNum: 0,
			oneMintCrsNumFm: 0,
			cIndex:6,
			icon: require('../../assets/img/icon_USDC.png'),
			url: 'https://pancakeswap.finance/swap?outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
		}
	]);
	
	import {provider} from "@/utils/provider.js";
	
	var web3js;
	if(store.state.isNet == 1){
		if (typeof window.ethereum === "undefined") {
			//没安装MetaMask钱包进行弹框提示
			web3js = new Web3();
		} else {
			web3js = new Web3(ethereum);
		}
	}
	else{
		web3js = new Web3(provider);
	}

	// 定义合约
	const approveUSDTContract = new web3js.eth.Contract(approveAbi, approveUsdtAddr);
	const approveBUSDContract = new web3js.eth.Contract(approveAbi, approveBUSDAddr);
	const approveUSDCContract = new web3js.eth.Contract(approveAbi, approveUSDCAddr);
	const mintContract = new web3js.eth.Contract(mintAbi, mintAddr);
	
	const getClaimInfo = ()=>{
		//查询可提现W3B
		mintContract.methods.earned(store.state.account).call().then((res) => {
			claimVal.value = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);
		})
		
		mintContract.methods.periodFinish(store.state.account).call().then((res) => {
			let mTime = formate.formatDecimal(new Date().getTime()/1000,0);
			let oTime = res;
			if(oTime <= mTime){
				PendingRewards.value = 0;
				Timefully.value = 0;
			}
			else{
				Timefully.value = formate.formatDecimal((oTime - mTime)/(3600*24),1);
				mintContract.methods.rewardRate(store.state.account).call().then((result) => {
					let newNum = result*(oTime - mTime);
					PendingRewards.value = formate.formatDecimal(web3js.utils.fromWei(BigInt(newNum).toString(),'ether'),4);
				})
			}
		})
	}
	//获取token余额
	const getCoinBalance = () => {
		// USDT
		approveUSDTContract.methods.balanceOf(store.state.account).call().then((res) => {
			CoinInfoList.value[0].balance = res;
			CoinInfoList.value[0].balanceFm = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);
		});
		// BUSD
		approveBUSDContract.methods.balanceOf(store.state.account).call().then((res) => {
			CoinInfoList.value[1].balance = res;
			CoinInfoList.value[1].balanceFm = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);
		});
		// USDC
		approveUSDCContract.methods.balanceOf(store.state.account).call().then((res) => {
			CoinInfoList.value[2].balance = res;
			CoinInfoList.value[2].balanceFm = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);
		});
	}
	//获取授权额度
	const getCoinApproveNum = () => {
		// USDT
		approveUSDTContract.methods.allowance(store.state.account, mintAddr).call().then((res) => {
			CoinInfoList.value[0].approveAmount = res;
			CoinInfoList.value[0].approveState = res > 0 ? true : false;
		});
		// BUSD
		approveBUSDContract.methods.allowance(store.state.account, mintAddr).call().then((res) => {
			CoinInfoList.value[1].approveAmount = res;
			CoinInfoList.value[1].approveState = res > 0 ? true : false;
		});
		// USDC
		approveUSDCContract.methods.allowance(store.state.account, mintAddr).call().then((res) => {
			CoinInfoList.value[2].approveAmount = res;
			CoinInfoList.value[2].approveState = res > 0 ? true : false;
		});
	}
	
	const getW3CPrice = ()=>{
		//获取CRS价格
		mintContract.methods.getW3CPrice().call().then((res) => {
			crsPrice.value = res;
			crsFromatePrice.value = formate.formatDecimal(web3js.utils.fromWei(crsPrice.value, 'ether'), 6);
		}).then(() => {
			for (let i = 4; i < 7; i++) {
				CoinInfoList.value[i-4].oneMintCrsNum = formate.formatDecimal(CoinInfoList.value[i-4].price / crsFromatePrice.value, 2);
				CoinInfoList.value[i-4].oneMintCrsNumFm = thousandth(formate.formatDecimal(CoinInfoList.value[i-4].price / crsFromatePrice.value, 2))
				mintContract.methods.vcAmount(i).call().then((result) => {
					CoinInfoList.value[i-4].totalMint = result;
					CoinInfoList.value[i-4].totalMintFm = formate.formatDecimal(web3js.utils.fromWei(result,'ether'), 2);
				});
			}
		});
	}
	
	

	const initData = ()=>{
		if (store.state.account) {
			// 获取最多可Mint的CRS数量
			mintContract.methods.dailyTime().call().then(res=>{
				console.log(res);
				let curTime = new Date().getTime()/1000;
				console.log(curTime);
				
				if(res<curTime){
					maxMintCrsNum.value = maxQuantity.value;
					maxMintCrsNumFormate.value = thousandth(maxQuantity.value);
				}
				else{
					mintContract.methods.dailyLimit().call().then((res) => {
						maxMintCrsNum.value = res;
						let fmData = web3js.utils.fromWei(res, 'ether');
						
						axios.get('https://74e49705-34bb-411e-928f-745c54f30b14.bspapp.com/api/data').then((resp) => {
							console.log(resp);
							let data = formate.formatDecimal(Number(fmData) - Number(resp.data.data[0].remaining),0)
							let val = data>0?data:0;
							maxMintCrsNumFormate.value = thousandth(val);
							
						}).catch((error) => {
							console.log(error);
						})
					});
				}
			})
			
			
			getW3CPrice();
		
			// 获取claim信息
			getClaimInfo();
			//执行获取token余额
			getCoinBalance();
			//执行获取授权额度
			getCoinApproveNum();
		}
	}
	
	onMounted(()=>{
		initData();
	})
	
	watch(store.state,(news,olds)=>{
		initData();
	})
	
	// 初始化计算可以获取多少W3B
	const initGetCrs = (num) => {
		return formate.formatDecimal(num * CoinInfoList.value[mintIndex.value].oneMintCrsNum, 4);
	}
	//切换mint弹窗tab
	const changeBarIndex = (index) => {
		mintBarIndex.value = index;
	}

	// 关闭弹窗
	const closeDialog = () => {
		mintAmount.value = '';
		canmintCrs.value = 0;
		CoinInfoList.value[mintIndex.value].approveState = CoinInfoList.value[mintIndex.value].approveAmount > 0 ?
			true : false;
	}

	// 获取输入mint的值改变W3B数量
	const getMintAmount = (num) => {
		canmintCrs.value = initGetCrs(num);
		let approveAmount = web3js.utils.fromWei(CoinInfoList.value[mintIndex.value].approveAmount.toString());
		if (Number(num) > Number(approveAmount) && mintIndex.value != 2) {
			ElMessage('Insufficient authorization ' + CoinInfoList.value[mintIndex.value].name +' limit, please authorize again');
			CoinInfoList.value[mintIndex.value].approveState = false;
		} else {
			if (CoinInfoList.value[mintIndex.value].approveAmount) {
				CoinInfoList.value[mintIndex.value].approveState = true;
			}
		}
	}

	//获取当前钱包余额
	const getMaxNum = () => {
		mintAmount.value = CoinInfoList.value[mintIndex.value].balanceFm;
		getMintAmount(mintAmount.value);
	}
	
	//mint
	const mint = () => {
		if (!mintAmount.value) {
			ElMessage('Input Amount');
			return false;
		}

		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}

		let amount = web3js.utils.toWei(mintAmount.value);
		let typeIndex = mintIndex.value + 4;

		if (BigInt(amount) > BigInt(CoinInfoList.value[mintIndex.value].approveAmount)) {
			ElMessage('Insufficient authorization ' + CoinInfoList.value[mintIndex.value].name +
				' limit, please authorize again');
			return false;
		}

		if (BigInt(amount) > BigInt(CoinInfoList.value[mintIndex.value].balance)) {
			ElMessage('Balance is not enough ,Balance is' + CoinInfoList.value[mintIndex.value].balanceFm +
				CoinInfoList.value[mintIndex.value].name);
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Mint';
		stateNum.value = 1;
		
		mintContract.methods.MintBondPool(amount, typeIndex).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			dialogFormVisible.value = false;
			mintAmount.value = '';
			getClaimInfo();
			getCoinBalance();
			getCoinApproveNum();
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}

	//claim提现
	const claim = () => {
		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}

		showModalState.value = true;
		StateInfo.value = 'Claim';
		stateNum.value = 1;
		
		mintContract.methods.claim().send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			dialogFormVisible.value = false;
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}



	// 打开mint弹窗
	const openMintBox = (name, index) => {
		if (!store.state.account) {
			ElMessage('No wallet is connected, please connect the wallet first');
			return false;
		}

		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}

		dialogFormVisible.value = true;
		mintIndex.value = index;
		dialogIndex.value = index;
	}

	//授权USDT额度
	const approveUSDT = (num) => {
		showModalState.value = true;
		StateInfo.value = 'Approve USDT';
		stateNum.value = 1;
		
		approveUSDTContract.methods.approve(mintAddr, num).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			getCoinApproveNum();
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}

	//授权BUSD额度
	const approveBUSD = (num) => {
		showModalState.value = true;
		StateInfo.value = 'Approve BUSD';
		stateNum.value = 1;
		
		approveBUSDContract.methods.approve(mintAddr, num).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			getCoinApproveNum()
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}

	//授权USDC额度
	const approveUSDC = (num) => {
		showModalState.value = true;
		StateInfo.value = 'Approve USDC';
		stateNum.value = 1;
		approveUSDCContract.methods.approve(mintAddr, num).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			getCoinApproveNum();
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}

	//授权额度弹窗
	const openApproveBox = (index) => {
		if (!store.state.account) {
			ElMessage('No wallet is connected, please connect the wallet first');
			return false;
		}

		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		let val = web3js.utils.toWei('100000000', 'ether');
		switch (index) {
			case 0:
				approveUSDT(val);
				break;
			case 1:
				approveBUSD(val);
				break;
			case 2:
				approveUSDC(val);
				break;
			default:
		
		}
		
		// ElMessageBox.prompt('Please enter the ' + name + ' limit you want to authorize', 'Tip', {
		// 		confirmButtonText: 'OK',
		// 		cancelButtonText: 'Cancel',
		// 		cancelButtonClass: 'cancelBtnClass',
		// 		confirmButtonClass: 'confrimBtnClass',
		// 		customClass: 'msgClass',
		// 		inputPlaceholder: 'Input Amount',
		// 		inputPattern: /^\d*\.?\d+$/,
		// 		inputErrorMessage: 'Invalid Amount',
		// 	})
		// 	.then(({
		// 		value
		// 	}) => {
				
		// 	})
		// 	.catch(() => {

		// 	})
	}

	// 添加代币到钱包
	const addCoinToWallet = (index) => {
		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		ethereum.request({
				method: 'wallet_watchAsset',
		 	params: {
					type: 'ERC20',
					options: {
						address: CoinInfoList.value[index].address,
						symbol: CoinInfoList.value[index].name,
						decimals: 18,
						image: CoinInfoList.value[index].image,
					},
				},
			})
			.then((success) => {
				if (success) {
					ElMessage('FIL successfully added to wallet!');
				} else {
					ElMessage('Something went wrong.');
				}
			})
			.catch(console.error);
	}
</script>

<style scoped>
	.row {
		margin-left: 0;
		margin-right: 0;
	}

	.add-w {
		font-size: 14px;
		cursor: pointer;
	}

	.max-coin {
		/* width:150px; */
		height: 40px;
		line-height: 40px;
		cursor: pointer;
		font-size: 16px;
		color: #fff;
	}

	.max-coin span {
		display: inline-block;
		width: 2px;
		height: 12px;
		margin: 0 10px;
		background-color: #999;
	}

	.mint-header {
		color: #fff;
	}

	.mint-header img {
		width: 40px;
	}

	.mint-btn {
		width: 40%;
		height: 44px;
		border-radius: 22px;
		border: 2px solid #620000;
		background-color: #620000;
		margin: 30px auto;
		cursor: pointer;
		font-size: 20px;
		font-weight: 500;
		color: #fff;
	}

	.mint-bar div {
		width: 120px;
		font-weight: 500;
		height: 60px;
		font-size: 20px;
		cursor: pointer;
		color: #999;
	}

	.mint-bar div.active {
		position: relative;
		color: #fff;
	}

	.mint-bar div.active:after {
		position: absolute;
		left: 50%;
		bottom: 10px;
		content: '';
		width: 80px;
		height: 2px;
		margin-left: -40px;
		background-color: #9F0E0E;
	}

	.pool-sub {
		font-size: 20px;
		font-weight: 500;
	}

	.pool-t div {
		width: 128px;
		height: 33px;
		background: #9F0E0E;
		border-radius: 17px;
		font-size: 14px;
		font-weight: 500;
	}

	.aprrove-btn {
		height: 44px;
		;
		background: #000000;
		border: 1px solid #FF0000;
		box-shadow: 0px -3px 9px 0px #530005;
		border-radius: 22px;
		line-height: 44px;
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
	}

	.buy-btn {
		position: relative;
		top: 12px;
		width: 60px;
		height: 30px;
		background-color: #AD0000;
		box-shadow: 0px -3px 9px 0px #530005;
		border-radius: 15px;
	}

	.buy-btn a {
		color: #fff;
		text-decoration: none;
	}

	.balance-item {
		height: 36px;
		color: #fff;
	}

	.balance-item:nth-child(2N+1) {
		background: #340000 !important;
		border-radius: 18px;
	}
	
	@media (max-width:768px) {
		.w3c-price {
			margin-top: 30px;
		}
		:deep .el-dialog__body{
			padding:0 25px!important;
		}
	}
</style>
